import React from 'react';
import ThemeTopLayout from 'gatsby-theme-material-ui-top-layout/src/components/top-layout';
import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocaleDe from 'date-fns/locale/de';
import { AppBar, IconButton, Dialog, Toolbar, Typography, DialogTitle, DialogContent, DialogActions, Button, DialogContentText, Box } from '@mui/material';
import { Help as HelpIcon } from '@mui/icons-material';

export default function TopLayout({ children, theme }: { children: React.ReactNode; theme: any; }) {
    const [helpModalOpen, setHelpModalOpen] = React.useState(false);
    const onClose = () => setHelpModalOpen(false);
    return (
        <ThemeTopLayout theme={theme}>
            <LocalizationProvider dateAdapter={DateAdapter} locale={LocaleDe}>
                <AppBar position='fixed' enableColorOnDark>
                    <Toolbar>
                        <Typography variant='h5'>Muss ich mich testen?</Typography>
                        <span style={{ flex: 1 }} />
                        <IconButton
                            color='secondary'
                            onClick={() => setHelpModalOpen(true)}
                        >
                            <HelpIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Dialog open={helpModalOpen} onClose={onClose}>
                    <DialogTitle>Info</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            DEINE MUDDA!<br />
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                            sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                            sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                            Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                            sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                            sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                            no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                            sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                            At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                            no sea takimata sanctus est Lorem ipsum dolor sit amet.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Ok</Button>
                    </DialogActions>
                </Dialog>
                <Toolbar sx={{ visibility: 'hidden', mb: { xs: 2, sm: 3 } }} />
                {children}
            </LocalizationProvider>
        </ThemeTopLayout>
    );
}
