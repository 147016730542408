import { createTheme, ThemeOptions } from "@mui/material";

const commonThemeOptions: ThemeOptions = {
    // typography: {
    //     fontFamily: [
    //         'Montserrat',
    //         'sans-serif'
    //     ].join(','),
    // },
}

const lightTheme = createTheme({
    palette: {
        background: {
            default: '#ebebeb',
        },
        primary: { main: '#1f5839' },
        secondary: { main: '#ff8000' },
    },
}, commonThemeOptions);

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        // background: {
        //     default: 'hsl(20deg, 100%, 40%)',
        //     paper: 'hsl(210deg, 100%, 60%)',
        // },
        primary: { main: '#1f5839' },
        secondary: { main: '#ff8000' },
    },
}, commonThemeOptions);

export default darkTheme;
